<template>
  <div>
    <b-modal
      id="m-update-password-maintenance"
      size="md"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      hide-header
    >
      <b-form @submit.prevent="showSweetAlertChangePassword">
        <span class="font-weight-bold">Silahkan ubah kata sandi anda! </span>
        <hr />
        <b-form-group
          id="fg-create-password"
          label="Kata Sandi"
          label-for="create-cabang-fpp"
          class="mt-3 required"
        >
          <b-form-input
            v-model="form.password"
            placeholder="Kata sandi"
            type="text"
            required
          ></b-form-input>
          <small class="text-danger" v-if="!$v.form.password.required"
            >Kata sandi tidak boleh kosong</small
          >
          <small
            class="text-danger"
            v-if="form.password && !$v.form.password.minLength"
            >Kata sandi minimal 6 karakter</small
          >
        </b-form-group>

        <b-form-group
          id="fg-create-password"
          label="Ulangi Kata Sandi"
          label-for="create-cabang-fpp"
          class="mt-2 required"
        >
          <b-form-input
            v-model="form.password_confirm"
            placeholder="Ulangi kata sandi"
            type="text"
            required
          ></b-form-input>
          <small class="text-danger" v-if="!$v.form.password_confirm.required"
            >Ulangi kata sandi tidak boleh kosong</small
          >
          <small
            class="text-danger"
            v-if="
              form.password_confirm && !$v.form.password_confirm.sameAsPassword
            "
            >Kata Sandi dan Ulangi Kata Sandi harus sama</small
          >
        </b-form-group>

        <b-button
          type="submit"
          variant="primary"
          :disabled="this.$v.form.$invalid"
        >
          <span v-show="!isSubmitting"
            ><i class="fa fa-save"></i> Simpan & Ubah Kata Sandi</span
          >

          <span v-show="isSubmitting">
            <span class="spinner-border spinner-border-sm"></span> Simpan & Ubah
            Kata Sandi
          </span>
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "/node_modules/vuelidate";
import {
  required,
  minLength,
  sameAs,
} from "/node_modules/vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  data: () => {
    return {
      form: {
        password: "",
        password_confirm: "",
      },
      isSubmitting: false,
      newUser: localStorage.getItem("is_new"),
    };
  },
  validations: {
    form: {
      password: { required, minLength: minLength(6) },
      password_confirm: { required, sameAsPassword: sameAs("password") },
    },
  },

  mounted() {
    if (this.newUser == 0) {
      this.showPopUpChangePassword();
    }
  },

  methods: {
    showPopUpChangePassword: function () {
      this.$bvModal.show("m-update-password-maintenance");
    },
    confirm: function () {
      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        return;
      } else {
        this.showSweetAlertChangePassword();
      }
    },

    onReset: function () {
      this.form = {
        password: "",
        password_confirm: "",
      };
    },

    showSweetAlertChangePassword: function () {
      let form = {
        password: this.form.password,
        password_confirmation: this.form.password_confirm,
      };

      this.isSubmitting = true;
      this.$axios
        .post("/api/auth/change-password", form)
        .then((response) => {
          if (response.status == 200) {
            this.$swal({
              title: "Sukses!",
              html: "<span>Kata sandi berhasil diubah</span><br /><small>Silahkan melakukan login kembali menggunakan kata sandi yang sudah anda diperbarui</small>",
              icon: "success",
              showConfirmButton: true,
              confirmButtonColor: "#1e73be",
              confirmButtonText: "Lanjut",
            }).then((result) => {
              if (result.isConfirmed) {
                this.onReset();
                this.logout();
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal({
            title: "Gagal!",
            html: "Kata sandi gagal diubah!",
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });
        })
        .finally(() => (this.isSubmitting = false));
    },

    logout() {
      this.$store.dispatch("destroyAuthentication").then(() => {
        this.$router.push({ name: "Login" });
      });
    },
  },
};
</script>

<style></style>
